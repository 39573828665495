require('prismjs/themes/prism-tomorrow.css');
// Tailwind must be at the end to allow css overwrite
require('./src/styles/tailwind.css');

export const onRouteUpdate = () => {
  if (process.env.NODE_ENV === 'production' || window.dev === true) {
    if (!window.mitchScriptCreatePixel) {
      return;
    }

    window.mitchScriptCreatePixel();
  }

  return null;
};
